import React from "react";
import cap2 from "../../images/cap2.jpg";
import { CloseIcon } from "../../Icons";

function Cartpage() {
  return (
    <div className="container mx-auto max-w-5xl mt-11 px-5">
      <div className="pb-5 border-b">
        <div className="flex justify-between">
          <div className="flex gap-10 items-end">
            <h1 className="font-bold font-LatoRegular text-xl md:text-3xl">
              Your Cart
            </h1>

            <h1 className="font-LatoRegular font-bold">3 items</h1>
          </div>

          {/* Total Money */}
          <div className="gap-5 items-end hidden sm:flex">
            <h1 className="font-LatoRegular font-bold">Total</h1>
            <h1 className="font-bold font-LatoRegular text-xl md:text-3xl">
              123 $
            </h1>
          </div>
        </div>
      </div>

      {/* Cart Items */}

      <div className="flex gap-16 flex-col mt-16">
        <div className="flex items-center gap-6 sm:gap-14 justify-between">
          <div className="">
            <img src={cap2} alt="" className="w-20 sm:w-28" />
          </div>

          {/* Prc */}
          <div className="flex gap-5 flex-1 items-center justify-between sm:w-full relative md:py-7 md:border-b">
            <h1 className="font-serif font-bold sm:text-2xl">Product</h1>

            {/* Quantity Change */}
            <div className=" items-center gap-7 hidden md:flex">
              <h1 className="font-bold font-serif text-lg">QTY</h1>
              <div className="flex gap-3 font-bold items-center">
                <button className="md:text-2xl font-serif">-</button>
                <p className="font-serif md:text-2xl">1</p>
                <button className="md:text-2xl font-serif">+</button>
              </div>
            </div>

            <h1 className="font-bold sm:text-xl">123 $</h1>

            <button className="font-extrabold md:absolute right-0 -top-3">
              <CloseIcon h="10" />
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Footer */}
      <div className="flex flex-col gap-8 mt-20 sm:flex-row w-full sm:justify-between">
        <div className="flex justify-between items-end">
          <small className="font-LatoRegular font-semibold text-sm text-slate-600 sm:hidden">
            TOTAL
          </small>
          <h1 className="font-LatoRegular text-xl font-bold sm:hidden">
            123 $
          </h1>
        </div>

        {/* Buy Button*/}
        <button className="bg-slate-700 py-2 text-white font-serif font-bold px-8">
          Buy Now
        </button>
      </div>
    </div>
  );
}

export default Cartpage;
