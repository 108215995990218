import React from "react";

function SectionPage({ aboutName, heading, pageAbout = [] }) {
  return (
    <div
      className="container mx-auto mt-20 max-w-7xl px-9 h-full"
      data-aos="zoom-in"
    >
      <div>
        <h1 className="text-xl">{aboutName}</h1>
        <p className="mt-5 font-serif font-bold text-3xl sm:text-4xl md:text-5xl md:max-w-lg lg:max-w-2xl">
          {heading}
        </p>
      </div>

      {/* Mission, Goal, Journey*/}
      <div className="flex gap-8 flex-col mt-8 md:flex-row lg:gap-8">
        {pageAbout.map((details, key) => (
          <div className="w-full">
            <h1 className="font-bold font-serif text-xl">{details.title}</h1>
            <p className="mt-2 leading-8 sm:text-xl lg:text-[15px]">
              {details.des}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SectionPage;
