import React from "react";
import Product from "./Product";
import cap1 from "../../images/Image 35.jpg";
import cap2 from "../../images/Image 36.jpg";
import hat from "../../images/Image 37.jpg";
import shirt from "../../images/Image 38.jpg";

function ShopPage() {
  return (
    <div className="container mx-auto max-w-5xl mt-8 overflow-hidden px-9">
      <div className="w-full relative ">
        {/* Sidebar */}
        {/* Products */}
        <div className="w-full">
          {/* Filter Head */}

          <h1 className="font-serif text-3xl font-bold lg:text-6xl">
            Welcome to our shop
          </h1>

          <p className="mt-5">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit
            impedit commodi, architecto, maxime doloremque eum illum officia
            enim quos exercitationem provident reprehenderit quibusdam sunt ab
            natus voluptas asperiores repellat vel!
          </p>

          <div className="flex justify-between items-center gap-5 flex-wrap mt-16">
            <select name="" id="" className="py-2 border border-black">
              <option value="">Sort by:</option>
              <option value="">Price Highest</option>
            </select>

            <div>
              <input
                type="text"
                placeholder="Search product"
                className="border border-black py-2 px-4 text-sm"
              />
            </div>
          </div>

          {/* Products */}

          <div className="grid grid-cols-2 mt-16 gap-3 sm:grid-cols-3 sm:gap-5 md:grid-cols-4 md:mt-14 lg:mt-20">
            <Product image={cap1} name="Black Cap" />
            <Product image={cap2} name="Gray Cap" />
            <Product image={shirt} name="Black T-Shirt" />
            <Product image={hat} name="Black T-Shirt" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopPage;
