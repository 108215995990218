import React from "react";
import bg from "../images/c-image.jpg";
import ContactInfo from "./ContactInfo";

function Contact() {
  return (
    <div className="container mx-auto max-w-7xl my-3 px-9">
      {/* Contact Banner */}
      <div className="w-full h-fit relative" data-aos="zoom-out">
        <img
          src={bg}
          alt=""
          className=" grayscale object-cover max-h-100 w-full"
        />
        <h1
          className="font-serif drop-shadow-lg font-bold text-center text-2xl sm:text-4xl 
        md:text-left md:text-6xl lg:text-7xl md:ml-10 text-white absolute 
        top-1/2 ml-5 -translate-y-1/2"
        >
          Let's{" "}
          <span className="font-serif underline text-green-500">Work</span>{" "}
          together
        </h1>
      </div>

      {/* Contact Info */}
      <ContactInfo />

      {/* Map */}

      <div className="mt-20">
        <iframe
          title="Kon"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31705.24357341082!2d-1.2313487911051615!3d6.627606275458825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdbe27be8bc0529%3A0x7906fad45f613972!2sKonongo!5e0!3m2!1sen!2sgh!4v1702312460966!5m2!1sen!2sgh"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}

export default Contact;
