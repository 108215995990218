import React from "react";

function About() {
  return (
    <div
      className="container mx-auto mt-28 max-w-7xl h-full px-5"
      data-aos="zoom-in"
    >
      <div>
        <h1 className="text-xl">ABOUT OUR AGENCY</h1>
        <p className="mt-5 font-serif text-primary font-bold text-3xl sm:text-4xl md:text-5xl md:max-w-lg lg:max-w-2xl lg:text-6xl">
          We create brands & digital experiences.
        </p>
      </div>

      {/* Mission, Goal, Journey*/}
      <div className="flex gap-8 flex-col mt-8 md:flex-row lg:gap-8">
        <div>
          <h1 className="font-bold font-serif text-2xl">Our Mission</h1>
          <p className="mt-2 leading-8 text-lg sm:text-xl lg:text-base">
            Our experienced team is ready to design, research, plan, cost,
            develop and launch. We are driven to get the best results for our
            clients.
          </p>
        </div>

        <div>
          <h1 className="font-bold font-serif text-2xl">Our Goals</h1>
          <p className="mt-2 leading-8 text-lg sm:text-xl lg:text-base">
            Our experienced team is ready to design, research, plan, cost,
            develop and launch. We are driven to get the best results for our
            clients.
          </p>
        </div>

        <div>
          <h1 className="font-bold font-serif text-2xl">Our Jouney</h1>
          <p className="mt-2 leading-8 text-lg sm:text-xl lg:text-base">
            Our experienced team is ready to design, research, plan, cost,
            develop and launch. We are driven to get the best results for our
            clients.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
