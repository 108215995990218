import React from "react";
import ShopPage from "./Shop/ShopPage";
import Header from "./Shop/Header";

function Shop() {
  document.title = "Shop";

  return (
    <div>
      {/* Shop Head */}
      <Header />

      {/* Product & Filtering Sidebar */}
      <ShopPage />
    </div>
  );
}

export default Shop;
