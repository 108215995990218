import React from "react";
import img1 from "../images/img1.jpg";
import img2 from "../images/img2.jpg";
import img3 from "../images/img3.jpg";
import img4 from "../images/img4.jpg";
import img5 from "../images/img5.jpg";
import { Link } from "react-router-dom";

function Portfolio() {
  return (
    <div className="container mx-auto px-9 mt-28">
      {/* Head */}
      <div className="mx-auto max-w-6xl">
        <h1 className="text-lg">OUR WORK</h1>
        <div className="flex justify-between items-center">
          <h1 className="font-bold text-3xl font-serif sm:text-4xl md:text-5xl lg:text-6xl">
            Here's what we do
          </h1>

          <Link
            to="/our-work"
            className="bg-black text-white py-2 px-4 rounded font-serif font-bold hidden md:block"
          >
            OUR WORK
          </Link>
        </div>
      </div>

      {/* Works */}

      <div
        className="columns-2 sm:columns-3 mt-10 md:columns-4 max-w-7xl mx-auto"
        data-aos="fade-in"
      >
        <img src={img1} alt="" className="rounded-2xl mb-3" />
        <img src={img2} alt="" className="rounded-2xl mb-3" />
        <img src={img3} alt="" className="rounded-2xl mb-3" />
        <img src={img4} alt="" className="rounded-2xl mb-3" />
        <img src={img5} alt="" className="rounded-2xl mb-3" />
      </div>

      <div className="flex justify-center md:hidden">
        <Link
          to="/our-work"
          className="bg-black mt-7 text-white py-2 px-4 rounded font-serif font-bold"
        >
          OUR WORK
        </Link>
      </div>
    </div>
  );
}

export default Portfolio;
