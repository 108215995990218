import React from "react";
import { Link } from "react-router-dom";

function Together() {
  return (
    <div className="mx-auto container mt-28 px-9" data-aos="zoom-out">
      <div className="w-full bg-black py-10 md:py-16 lg:py-24">
        <div className="flex flex-col gap-4 items-center">
          <h1 className="font-serif text-white text-3xl font-bold text-center md:text-4xl lg:text-7xl">
            Let's{" "}
            <span className="font-serif underline text-green-500">Work</span>{" "}
            together
          </h1>

          <div className="flex flex-wrap">
            <Link to="/contact" className="text-white">
              Contact Us &rarr
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Together;
