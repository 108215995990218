import React from "react";
import bannerImg from "../images/me.png";

function Banner() {
  return (
    <div className="container mx-auto max-w-7xl mt-5 lg:mt-16 h-full overflow-hidden px-9">
      <div className="flex justify-center items-center">
        <div className="flex items-center gap-7 h-full flex-col md:flex-row">
          {/* Banner Description */}

          <div className="w-full overflow-hidden">
            <h1
              className="text-4xl lg:text-6xl leading-tight font-bold font-serif text-primary text-center sm:text-5xl md:text-left"
              data-aos="slide-left"
            >
              We Design Experiences For Growth
            </h1>

            <div className="mt-6 text-center md:text-left">
              <button
                className="py-1.5 px-5 border rounded border-black font-bold hover:bg-black hover:text-white"
                data-aos="fade-right"
              >
                Learn More
              </button>
            </div>
          </div>

          {/* Banner Image */}

          <div className="w-full overflow-hidden">
            <img src={bannerImg} alt="" data-aos="zoom-out" />
          </div>
        </div>
      </div>

      <h1
        className="font-sans font-bold max-w-[700px] mx-auto text-center text-lg md:text-2xl mt-5 leading-9 tracking-widest"
        data-aos="zoom-in"
      >
        A branding & web design agency that specializes in branding, engagement,
        and revenue.
      </h1>
    </div>
  );
}

export default Banner;
