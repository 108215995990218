import React from "react";
import { Link } from "react-router-dom";
import { FacebookIcon, InstagramIcon, TwitterIcon } from "../Icons";

function Footer() {
  return (
    <div className="lg:p-10 p-4 bg-gradient-to-b from-slate-950 to-green-900 mt-12">
      <div className="bg-white lg:py-16 h-full hover:scale-110 delay-200 ease-linear transition p-9 2xl:max-w-7xl mx-auto">
        <div className="flex flex-col gap-9 md:flex-row md:justify-between lg:max-w-4xl mx-auto">
          <div className="flex gap-4 flex-col w-full">
            <h1 className="font-serif font-bold text-xl">Quick Links</h1>
            <ul className="flex flex-col gap-2 mt-4">
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/our-work">Work</Link>
              </li>
              <li>
                <Link to="/what-we-do">What we do</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>

            <div className="mt-28">
              <h1 className="font-bold font-serif text-lg">
                godsgreat // lets create memory together ™
              </h1>

              <p className="mt-2">Ashanti Region, Konongo | +233553772148</p>
            </div>
          </div>

          {/* 2nd Row */}
          <div className="w-full">
            <div className="flex gap-5 items-center justify-end">
              <TwitterIcon fill="#000" />
              <InstagramIcon fill="#000" />
              <FacebookIcon fill="#000" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
