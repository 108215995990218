import React from "react";

function Product({ name, id, price, image }) {
  return (
    <div>
      <div className="flex flex-gap relative flex-col gap-4">
        {/* Product Image */}
        <div className="text-center w-full">
          <img src={image} alt="" className="w-full" />
        </div>

        {/* Product Description */}
        <div className="">
          <h1 className="font-semibold sm:text-lg md:text-xl">{name}</h1>
          <p className=" text-gray-500 mt-2 md:text-lg">$132.98</p>
        </div>
      </div>
    </div>
  );
}

export default Product;
