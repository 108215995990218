import axios from "axios";
import React, { useState } from "react";

function Ready({ targetRef, title }) {

  const pageUrl = document.location.href.split('/')

  const interested = pageUrl[pageUrl.length - 1]

  const [loading, setloading] = useState(false)

  const [data, setData] = useState({
    interested,
    firstname: "",
    lastname: "",
    email: "",
    company: "",
    message: "",
  })

  const inputChanged = (e) => {
    const { id, value } = e.target;
    setData({ ...data, [id]: value })
  }

  const sendMessage = async () => {
    setloading(true)
    try {
      const response = await axios.post('https://api.godgreatprintx.com/send', data)
      setloading(false)
      setData({
        interested,
        firstname: "",
        lastname: "",
        email: "",
        company: "",
        message: "",
      })
      alert(response.data)
    } catch (error) {
      setloading(false)
      alert(error.response.data)
    }

  };



  return (
    <div className="container mx-auto mt-16" ref={targetRef}>
      <div className="bg-black pt-14 pb-20  md:pb-28 lg:pb-48">
        <h1 className="font-serif leading-tight font-bold text-white text-center text-xl sm:text-3xl md:text-4xl lg:text-6xl max-w-3xl mx-auto">
          Ready to work with us
        </h1>
      </div>

      {/* Contact Form */}
      <div className="w-full -mt-14 lg:-mt-36 px-4 " data-aos="fade-out">
        <div className="flex flex-col gap-6 max-w-6xl mx-auto bg-white md:p-11 p-4 sm:p-6 drop-shadow-2xl rounded-2xl">
          {/* 2nd */}

          <div className="flex flex-col gap-5 lg:flex-row w-full">
            <div className="w-full">
              <h1 className="mb-2 font-extrabold">First Name</h1>
              <input
                type="text"
                className="w-full border border-black rounded py-2 px-2 text-sm"
                value={data.firstname}
                id="firstname"
                onChange={inputChanged}
              />
            </div>
            <div className="w-full">
              <h1 className="mb-2 font-extrabold">Last Name</h1>
              <input
                type="text"
                className="w-full border border-black rounded py-2 px-2 text-sm"
                value={data.lastname}
                id="lastname"
                onChange={inputChanged}
              />
            </div>
          </div>

          {/* 3rd */}

          <div className="flex flex-col gap-5 lg:flex-row w-full">
            <div className="w-full">
              <h1 className="mb-2 font-extrabold">Email *</h1>
              <input
                type="email"
                className="w-full border border-black rounded py-2 px-2 text-sm"
                value={data.email}
                id="email"
                onChange={inputChanged}
              />
            </div>
            <div className="w-full">
              <h1 className="mb-2 font-extrabold">Company</h1>
              <input
                type="text"
                className="w-full border border-black rounded py-2 px-2 text-sm"
                value={data.company}
                id="company"
                onChange={inputChanged}
              />
            </div>
          </div>

          {/* 4th */}

          <div>
            <h1 className="mb-2 font-extrabold">Share your ideas with us</h1>
            <textarea
              cols="30"
              rows="6"
              className="border border-black rounded w-full p-2 text-sm"
              value={data.message}
              id="message"
              onChange={inputChanged}
            ></textarea>
          </div>

          {/* Submit Btn */}
          <div>
            <button onClick={sendMessage} className="bg-green-900 text-white px-5 py-2 rounded font-serif font-bold">
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ready;
