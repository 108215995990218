import React from "react";
import About from "./About";
import img from "../images/business.jpeg";
import img1 from "../images/img1.jpg";
import img2 from "../images/img2.jpg";
import img3 from "../images/img3.jpg";
import img4 from "../images/img4.jpg";
import img5 from "../images/img5.jpg";
import Clients from "./Clients";
import Together from "./Together";

function AboutUs() {
  return (
    <div className="container mx-auto mt-16 px-5 overflow-hidden ">
      {/* Heading */}
      <div className="max-w-7xl mx-auto">
        <div className="flex gap-8 flex-col md:flex-row">
          <h1 className="font-serif font-bold w-full text-3xl text-primary text-center md:text-left lg:text-4xl">
            A creatively driven team who are passionate about new ideas
          </h1>

          <p className="w-full text-center md:text-left lg:text-lg leading-7">
            We are branding pioneers, and we’re ready to work alongside you to
            scale your business's identity. Whether this is through your
            website, social media, or content creation; we will design and work
            with all our love. We believe there’s no such thing as just doing a
            job. We are passionate about our work, and we want to bring your
          </p>
        </div>
      </div>

      {/* Picture BCard */}
      <div className="w-full mt-28 mx-auto max-w-5xl">
        <img src={img} alt="" className="" />
      </div>

      {/* About US */}
      <About />

      {/* More Pictures */}
      <div className="columns-2 sm:columns-3 md:columns-4 mt-28">
        <img src={img1} alt="" className="mb-5" />
        <img src={img2} alt="" className="mb-5" />
        <img src={img3} alt="" className="mb-5" />
        <img src={img4} alt="" className="mb-5" />
        <img src={img5} alt="" className="mb-5" />
      </div>

      {/* Clients */}
      <Clients />

      {/* Together  */}
      <Together />
    </div>
  );
}

export default AboutUs;
