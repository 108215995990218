import React, { useRef } from "react";
import WhatWedoComponent from "./WhatWedoComponent";
import img1 from "../images/img2.jpg";
import img2 from "../images/img4.jpg";
import img3 from "../images/img5.jpg";
import bannerImg from "../images/bq.jpeg";
import { eventPlanning } from "../Database";
import ContactInfo from "./ContactInfo";

function WhatWeDo() {
  const targetRef = useRef(null);

  // Function to handle the button click event
  const scrollToElement = () => {
    // Scroll to the target element using scrollIntoView
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="mt-3">
      {/* Banner */}
      <div className="flex justify-center items-center mx-auto max-w-7xl mb-16 px-5">
        <div className="flex items-center lg:gap-10 gap-7 h-full flex-col md:flex-row">
          {/* Banner Description */}

          <div className="w-full overflow-hidden" data-aos="zoom-out">
            <h1 className="text-4xl text-primary break-keep lg:text-6xl leading-tight font-bold font-serif text-center sm:text-5xl md:text-left">
              Branding Starts Here
            </h1>

            <p className="mt-7 leading-7">{eventPlanning.bannerInfo.des}</p>

            <div className="mt-6 text-center md:text-left">
              <button
                onClick={scrollToElement}
                className="py-2.5 transition hover:bg-black hover:text-white px-5 border rounded border-black font-bold font-serif"
              >
                Have a project in mind? Let's connect
              </button>
            </div>
          </div>

          {/* Banner Image */}

          <div className="w-full overflow-hidden">
            <img
              src={bannerImg}
              alt=""
              data-aos="zoom-out"
              className="w-full h-auto"
            />
          </div>
        </div>
      </div>

      <div>
        <WhatWedoComponent
          sub="WHAT WE DO"
          title="Connecting Hearts to Events"
          list={[
            "Offset Printing",
            "Digital Printing",
            "Large Format Printing",
            "Custom Printing",
            "Finishing Options",
          ]}
          img={img1}
          title2="Printing Services & Branding"
          des="Printing isn't just a service; it's an art form. Discover how our skilled team combines creativity and precision to bring your ideas to life in vivid color and sharp detail."
        />

        <WhatWedoComponent
          bgColor="white"
          reversed={true}
          list={[
            "Landscape Photography",
            "Portrait Photography",
            "Event Photography",
            "Travel Photography",
            "Macro Photography",
          ]}
          img={img2}
          title2="Photography & Videography"
          des="Through the art of photography, I strive to find the extraordinary in the everyday. Join me on a visual journey that will inspire and ignite your imagination."
        />

        <WhatWedoComponent
          list={[
            "Weddings",
            "Corporate Events",
            "Social Gatherings",
            "Theme Parties",
          ]}
          img={img3}
          title2="Event Planning"
          des="We believe that every event should be a unique reflection of your style and personality. Trust us to bring your vision to life, down to the smallest detail, for an unforgettable celebration."
        />
      </div>

      <ContactInfo
        title={"How can we help? Let's work together"}
        hrefname={targetRef}
      />
    </div>
  );
}

export default WhatWeDo;
