import React from "react";
import { Link } from "react-router-dom";
import img from "../images/business.jpeg";
import img1 from "../images/c-image.jpg";

function OurWork() {
  return (
    <div className="container mx-auto px-5 md:mt-10">
      <div className="mx-auto max-w-6xl">
        <Link
          to=""
          className="font-serif font-bold text-gray-500 text-2xl sm:text-3xl"
        >
          Work
        </Link>

        {/* All Works Place */}

        <div className="flex flex-col gap-2 mt-4 md:flex-row md:justify-between md:gap-9">
          <div>
            <Link className="mt-5 font-serif font-bold sm:text-xl">
              Printing & Branding
            </Link>
          </div>
          <div>
            <Link className="mt-5 font-serif font-bold sm:text-xl">
              Photography
            </Link>
          </div>
          <div className="">
            <Link className="mt-5 font-serif font-bold sm:text-xl">
              Event Planning
            </Link>
          </div>
        </div>
      </div>

      <hr className="border-black mt-16" />

      {/* Pictures */}
      <div className="columns-1 mt-11 md:columns-2">
        <img src={img} alt="" className="w-full mb-5" />
        <img src={img1} alt="" className="w-full mb-5" />
        <img src={img} alt="" className="w-full mb-5" />
        <img src={img1} alt="" className="w-full mb-5" />
        <img src={img} alt="" className="w-full mb-5" />
        <img src={img1} alt="" className="w-full mb-5" />
        <img src={img} alt="" className="w-full mb-5" />
        <img src={img1} alt="" className="w-full mb-5" />
        <img src={img} alt="" className="w-full mb-5" />
        <img src={img1} alt="" className="w-full mb-5" />
        <img src={img} alt="" className="w-full mb-5" />
      </div>
    </div>
  );
}

export default OurWork;
