export function CartBusket() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.834"
      height="25"
      viewBox="0 0 31.834 25"
    >
      <path
        id="Path_238"
        data-name="Path 238"
        d="M980.771,3807.628l-3.7,11.689a1.96,1.96,0,0,1-1.883,1.367h-14.3a2.051,2.051,0,0,1-1.9-1.271l-5.446-14.646h-3.159a1.384,1.384,0,1,1,0-2.768H954.5a1.429,1.429,0,0,1,1.3.93l5.648,14.987h13.161l2.957-9.343H961.253a1.384,1.384,0,0,1,0-2.768h18.2a1.411,1.411,0,0,1,1.116.587A1.388,1.388,0,0,1,980.771,3807.628Zm-19.172,14.9a2.271,2.271,0,0,0-1.592.654,2.228,2.228,0,0,0,0,3.161,2.262,2.262,0,0,0,3.18,0,2.234,2.234,0,0,0-1.588-3.814Zm12.419,0a2.266,2.266,0,0,0-1.592.654,2.228,2.228,0,0,0,0,3.161,2.251,2.251,0,0,0,3.841-1.581,2.244,2.244,0,0,0-2.249-2.234Z"
        transform="translate(-949.001 -3801.999)"
      />
    </svg>
  );
}

export function Humburger() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="26.182"
      viewBox="0 0 27 26.182"
    >
      <path
        id="Path_243"
        data-name="Path 243"
        d="M964.864,3366.727a2.863,2.863,0,1,1,2.025-.839A2.89,2.89,0,0,1,964.864,3366.727Zm12.661-.839a2.864,2.864,0,1,0-2.025.839A2.889,2.889,0,0,0,977.526,3365.888Zm10.636,0a2.864,2.864,0,1,0-2.025.839A2.89,2.89,0,0,0,988.162,3365.888Zm-21.273,10.227a2.864,2.864,0,1,0-2.025.839A2.889,2.889,0,0,0,966.889,3376.116Zm10.636,0a2.864,2.864,0,1,0-2.025.839A2.889,2.889,0,0,0,977.526,3376.116Zm10.636,0a2.864,2.864,0,1,0-2.025.839A2.89,2.89,0,0,0,988.162,3376.116Zm-21.273,10.227a2.864,2.864,0,1,0-2.025.839A2.889,2.889,0,0,0,966.889,3386.343Zm10.636,0a2.864,2.864,0,1,0-2.025.839A2.889,2.889,0,0,0,977.526,3386.343Zm10.636,0a2.864,2.864,0,1,0-2.025.839A2.89,2.89,0,0,0,988.162,3386.343Z"
        transform="translate(-962 -3361)"
      />
    </svg>
  );
}

export function ArrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="16"
      viewBox="0 0 76 58"
    >
      <path
        id="Path_56"
        data-name="Path 56"
        d="M1253.832,953.825l-24.933,25a4,4,0,0,1-5.664-5.65L1241.361,955H1183a4,4,0,0,1,0-8h58.361l-18.126-18.175a4,4,0,0,1,5.664-5.65l24.933,25A4,4,0,0,1,1253.832,953.825Z"
        transform="translate(-1179 -922)"
      />
    </svg>
  );
}

export function ChevDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.021"
      height="10.56"
      viewBox="0 0 21.021 10.56"
    >
      <path
        id="Path_240"
        data-name="Path 240"
        d="M2530.51,944.56a1.576,1.576,0,0,1-1.044-.381L2520.4,936.1a1.121,1.121,0,0,1,.041-1.757,1.624,1.624,0,0,1,2.049.035l8.016,7.144,8.017-7.144a1.625,1.625,0,0,1,2.049-.035,1.121,1.121,0,0,1,.04,1.757l-9.061,8.075A1.577,1.577,0,0,1,2530.51,944.56Z"
        transform="translate(-2520 -933.999)"
        fill="#fff"
      />
    </svg>
  );
}

export function UserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 92 93"
    >
      <path
        id="Path_241"
        data-name="Path 241"
        d="M773,2960.094a27.047,27.047,0,1,0-26.925-27.049A27.018,27.018,0,0,0,773,2960.094ZM773,2914a19.047,19.047,0,1,1-18.925,19.045A19.007,19.007,0,0,1,773,2914Zm24.466,51.741a106.911,106.911,0,0,0-15.808-2.237H764.343a66.587,66.587,0,0,0-12.807,1.237c-5.6,1.786-24.535,16.4-24.535,30.259a4,4,0,0,0,4,4h84a4,4,0,0,0,4-4C819,2981.141,803.066,2967.527,797.467,2965.741ZM737.1,2991c4.411-4.668,10-12.756,14.779-17.94,16.853-1.807,33.414-1.3,45.243,1,1.228,1.671,2.414,3.507,3.768,5.65,2.41,3.815,4.99,8.092,8.011,11.29Z"
        transform="translate(-727.001 -2906)"
      />
    </svg>
  );
}

export function CartBagIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="25"
      viewBox="0 0 70.001 90"
    >
      <path
        id="Path_188"
        data-name="Path 188"
        d="M1250.83,3816.249A4.079,4.079,0,0,0,1248,3815h-10v-1.826c0-11.676-9.585-21.174-20.786-21.174h-.425c-11.2,0-20.789,9.5-20.789,21.174V3815h-10a4.136,4.136,0,0,0-4,4.155V3878a4,4,0,0,0,4,4h61.981a4,4,0,0,0,4-4l.019-58.884A4.092,4.092,0,0,0,1250.83,3816.249Zm-46.83-3.075A13.173,13.173,0,0,1,1216.789,3800h.425A13.171,13.171,0,0,1,1230,3813.174V3815h-26ZM1243.983,3874H1190v-51h6v6.931a4,4,0,0,0,8,0V3823h26v6.931a4,4,0,0,0,8,0V3823h6Z"
        transform="translate(-1182 -3792)"
      />
    </svg>
  );
}

export function CloseIcon({ h = 17 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.671"
      height={h}
      viewBox="0 0 17.671 17.357"
    >
      <g
        id="Group_72"
        data-name="Group 72"
        transform="translate(-2648.086 -1204.085)"
      >
        <path
          id="Path_248"
          data-name="Path 248"
          d="M2663.367,5553.247l-14.843,14.528"
          transform="translate(0.977 -4347.747)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <path
          id="Path_249"
          data-name="Path 249"
          d="M2648.523,5553.247l14.843,14.528"
          transform="translate(0.977 -4347.747)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export function FacebookIcon({ fill = "#fff" }) {
  return (
    <svg
      id="Layer_x0020_1"
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24.824"
      viewBox="0 0 25 24.824"
    >
      <path
        id="Path_245"
        data-name="Path 245"
        d="M24.978,12.489A12.489,12.489,0,1,0,10.545,24.824V16.092H7.37V12.478h3.174V9.732c0-3.13,1.856-4.866,4.723-4.866a19.333,19.333,0,0,1,2.79.242V8.183H16.487a1.8,1.8,0,0,0-2.032,1.944v2.351h3.46l-.549,3.614H14.455v8.732A12.494,12.494,0,0,0,25,12.489Z"
        fill={fill}
      />
    </svg>
  );
}

export function TwitterIcon({ fill = "#fff" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="20.307"
      viewBox="0 0 25 20.307"
    >
      <g id="Layer_x0020_1" transform="translate(0.02 0.01)">
        <path
          id="Path_247"
          data-name="Path 247"
          d="M22.414,5.057A11.092,11.092,0,0,0,24.98,2.414a9.894,9.894,0,0,1-2.953.786A5.092,5.092,0,0,0,24.271.377a10.108,10.108,0,0,1-3.249,1.238,5.118,5.118,0,0,0-8.858,3.494,5.84,5.84,0,0,0,.129,1.173A14.577,14.577,0,0,1,1.733.918a5.028,5.028,0,0,0-.7,2.592A5.128,5.128,0,0,0,3.319,7.778a5.268,5.268,0,0,1-2.308-.645V7.2a5.12,5.12,0,0,0,4.1,5.028,5.811,5.811,0,0,1-1.354.181,7.931,7.931,0,0,1-.967-.077,5.116,5.116,0,0,0,4.783,3.546,10.259,10.259,0,0,1-6.356,2.192A9.824,9.824,0,0,1-.02,18,14.492,14.492,0,0,0,7.845,20.3,14.473,14.473,0,0,0,22.414,5.727c0-.219,0-.451-.013-.67Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export function InstagramIcon({ fill = "#fff" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24.99"
      viewBox="0 0 25 24.99"
    >
      <g id="Layer_x0020_1" transform="translate(-0.02 -0.028)">
        <path
          id="Path_246"
          data-name="Path 246"
          d="M12.529,6.109a6.4,6.4,0,1,0,6.409,6.409,6.394,6.394,0,0,0-6.409-6.409Zm0,10.572a4.163,4.163,0,1,1,4.163-4.163,4.171,4.171,0,0,1-4.163,4.163ZM20.69,5.852A1.494,1.494,0,1,0,19.2,7.346,1.5,1.5,0,0,0,20.69,5.852Zm4.245,1.515a7.407,7.407,0,0,0-2.02-5.234A7.452,7.452,0,0,0,17.681.113C15.62,0,9.438,0,7.367.113A7.46,7.46,0,0,0,2.132,2.122,7.452,7.452,0,0,0,.113,7.356C-.011,9.417-.011,15.6.113,17.67A7.407,7.407,0,0,0,2.132,22.9a7.452,7.452,0,0,0,5.234,2.02c2.061.124,8.243.124,10.314,0a7.407,7.407,0,0,0,5.234-2.02,7.452,7.452,0,0,0,2.02-5.234c.113-2.061.113-8.243,0-10.3ZM22.266,19.886a4.19,4.19,0,0,1-2.37,2.37c-1.638.649-5.544.5-7.367.5s-5.719.144-7.367-.5a4.19,4.19,0,0,1-2.37-2.37c-.649-1.649-.5-5.544-.5-7.367s-.144-5.729.5-7.367a4.19,4.19,0,0,1,2.37-2.37c1.649-.659,5.544-.5,7.367-.5s5.729-.144,7.367.5a4.19,4.19,0,0,1,2.37,2.37c.659,1.638.5,5.544.5,7.367S22.926,18.237,22.266,19.886Z"
          transform="translate(0 0)"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export function DownPin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="11"
      viewBox="0 0 22 11"
    >
      <path
        id="Polygon_1"
        data-name="Polygon 1"
        d="M11,0,22,11H0Z"
        transform="translate(22 11) rotate(180)"
        fill="#fff"
      />
    </svg>
  );
}
