import axios from "axios";
import React, { useState } from "react";

function ContactInfo({ title, hrefname }) {


  const [loading, setloading] = useState(false)

  const [data, setData] = useState({
    interested: "Event Planning",
    firstname: "",
    lastname: "",
    email: "",
    company: "",
    message: "",
  });

  const inputChange = (e) => {
    const { value, id } = e.target;
    setData({ ...data, [id]: value });
  };

  const sendMessage = async () => {
    setloading(true)
    try {
      const response = await axios.post('https://api.godgreatprintx.com/send', data)
      setloading(false)
      setData({
        interested: "Event Planning",
        firstname: "",
        lastname: "",
        email: "",
        company: "",
        message: "",
      })
      alert(response.data)
    } catch (error) {
      setloading(false)
      alert(error.response.data)
    }

  };

  return (
    <div className="w-full mt-20 p-5" data-aos="fade-out" ref={hrefname}>
      <h1 className="text-center font-serif font-bold mb-10 text-2xl sm:text-3xl md:text-4xl">
        {title}
      </h1>

      <div className="flex flex-col gap-6 max-w-6xl mx-auto">
        <div>
          <h1 className="mb-2 font-extrabold">I am interested in</h1>
          <select
            className="w-full border border-black rounded py-2 px-2 text-sm"
            onChange={inputChange}
            id="interested"
          >
            <option value="Event Planning">Event Planning</option>
            <option value="Photography">Photography</option>
            <option value="Printing Services">Printing Services</option>
          </select>
        </div>

        {/* 2nd */}

        <div className="flex flex-col gap-5 lg:flex-row w-full">
          <div className="w-full">
            <h1 className="mb-2 font-extrabold">First Name</h1>
            <input
              type="text"
              value={data.firstname}
              onChange={inputChange}
              id="firstname"
              className="w-full border border-black rounded py-2 px-2 text-sm"
            />
          </div>
          <div className="w-full">
            <h1 className="mb-2 font-extrabold">Last Name</h1>
            <input
              type="text"
              value={data.lastname}
              onChange={inputChange}
              id="lastname"
              className="w-full border border-black rounded py-2 px-2 text-sm"
            />
          </div>
        </div>

        {/* 3rd */}

        <div className="flex flex-col gap-5 lg:flex-row w-full">
          <div className="w-full">
            <h1 className="mb-2 font-extrabold">Email *</h1>
            <input
              type="email"
              value={data.email}
              onChange={inputChange}
              id="email"
              className="w-full border border-black rounded py-2 px-2 text-sm"
            />
          </div>
          <div className="w-full">
            <h1 className="mb-2 font-extrabold">Company</h1>
            <input
              value={data.company}
              onChange={inputChange}
              id="company"
              type="text"
              className="w-full border border-black rounded py-2 px-2 text-sm"
            />
          </div>
        </div>

        {/* 4th */}

        <div>
          <h1 className="mb-2 font-extrabold">
            Introduce yourself, let us get to know you
          </h1>
          <textarea
            cols="30"
            value={data.message}
            onChange={inputChange}
            id="message"
            rows="6"
            className="border border-black rounded w-full p-2 text-sm"
          ></textarea>
        </div>

        {/* Submit Btn */}
        <div>
          <button
            onClick={sendMessage}
            className="bg-green-900 text-white px-5 py-2 rounded font-serif font-bold"
          >
            {loading ? "Loading..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ContactInfo;
