import React, { useState } from "react";
import { DownPin, FacebookIcon, InstagramIcon, TwitterIcon } from "../Icons";
import { Link } from "react-router-dom";

function Menu({ openMenu }) {
  const [isOpen, setisOpen] = useState(false);

  return (
    <div
      className="container mx-auto max-w-5xl relative h-full w-full"
      data-aos="zoom-in"
    >
      {/* Close Menu */}

      <div className="w-full flex justify-end">
        <button
          className="cursor-pointer text-white text-xl"
          onClick={() => {
            openMenu();
          }}
        >
          Close Menu
        </button>
      </div>

      {/* Nav */}

      <nav className="mt-32 relative lg:ml-22 overflow-hidden">
        <ul className="text-white text-3xl flex flex-col gap-5 font-bold ">
          <li>
            <Link
              to="/about-us"
              className="font-serif transition hover:text-green-800"
              onClick={() => {
                openMenu();
              }}
            >
              About
            </Link>
          </li>

          <li className="overflow-hidden md:hidden">
            <button
              className="font-serif overflow-hidden"
              onClick={() => {
                setisOpen(!isOpen);
              }}
            >
              <div className="flex gap-10 items-center">
                <p className="font-serif">Services</p>
                <div>
                  <DownPin />
                </div>
              </div>

              {/* Services */}
              {isOpen && (
                <div className="flex flex-col gap-5 mt-5 overflow-hidden">
                  <Link
                    to="/printing"
                    className="text-left text-2xl font-serif"
                    onClick={() => {
                      openMenu();
                    }}
                  >
                    Printing
                  </Link>

                  <Link
                    to="/photography"
                    className="text-left text-2xl font-serif"
                    onClick={() => {
                      openMenu();
                    }}
                  >
                    Photography
                  </Link>

                  <Link
                    to="/event-planning"
                    className="text-left text-2xl font-serif"
                    onClick={() => {
                      openMenu();
                    }}
                  >
                    Event Planning
                  </Link>
                </div>
              )}
            </button>
          </li>

          <li>
            <Link
              to="/our-work"
              className="font-serif transition hover:text-green-800"
              onClick={() => {
                openMenu();
              }}
            >
              Our Work
            </Link>
          </li>

          <li>
            <Link
              to="/what-we-do"
              className="font-serif transition hover:text-green-800"
              onClick={() => {
                openMenu();
              }}
            >
              What We do
            </Link>
          </li>
        </ul>
      </nav>

      {/* Social Medias */}

      <div className="flex items-center justify-end mt-36 pr-20">
        <div>
          <div className="flex gap-5 items-center justify-end">
            <TwitterIcon />
            <InstagramIcon />
            <FacebookIcon />
          </div>
          <div className="font-serif text-white font-bold mt-4">
            Branding Starts Here
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
