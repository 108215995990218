import React from "react";

function WhatWedoComponent({
  bgColor = "black",
  reversed = false,
  sub,
  title,
  title2,
  des,
  img,
  list,
}) {
  console.log(list);

  return (
    <div
      className="container mx-auto p-8 sm:p-15 max-w-7xl sm:p-20 md:px-24 md:py-14 lg:px-36"
      style={{
        backgroundColor: bgColor,
        color: bgColor === "black" ? "white" : "black",
      }}
    >
      {/* What We do Content */}

      <div className="flex flex-col">
        {/* Content */}

        <div>
          <h1 className="font-LatoRegular text-center sm:text-lg">{sub}</h1>

          <h1 className="mt-5 font-serif font-bold text-2xl text-center sm:text-4xl md:text-5xl">
            {title}
          </h1>

          {/* Content divs */}
          <div
            className={`flex flex-col mt-10 gap-9 md:flex-row md:gap-5 lg:gap-10 ${
              reversed ? "md:flex-row-reverse" : "md:flex-row"
            }`}
          >
            <div className="w-full">
              <img
                src={img}
                alt=""
                className="w-full max-h-[500px] object-cover"
              />
            </div>
            {/* 2nd */}
            <div className="w-full">
              <h1 className="font-serif text-xl font-bold sm:text-2xl md:text-4xl text-green-400">
                {title2}
              </h1>

              <p className="mt-5 leading-7 text-sm sm:text-lg lg:text-base">
                {des}
              </p>

              <h1 className="font-LatoRegular text-xl mt-5">Services</h1>

              <ul className="list-disc mt-3 ml-3 leading-8">
                {list?.map((item, key) => (
                  <li key={key}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatWedoComponent;
