import React from "react";
import { CartBagIcon, UserIcon } from "../../Icons";

function Header() {
  return (
    <div className="container mx-auto max-w-[1100px]">
      <div className="py-6">
        <div className="flex items-center justify-between">
          {/* Shop Logo */}
          <div className="flex gap-28 items-center">
            <div>
              <h1 className="font-bold text-3xl font-serif">greatshop</h1>
            </div>

            {/* Shop Navigation */}
            <ul className="flex gap-7 items-center">
              <li>Shop</li>
              <li>Sale</li>
              <li>Contact</li>
            </ul>
          </div>

          {/* Shop Actions */}
          <div className="flex items-center gap-10">
            <UserIcon />
            <CartBagIcon />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
