import React, { useRef } from "react";
import Ready from "./Ready";
import bannerImg from "../images/me.png";
import bannerImg1 from "../images/business.jpeg";
import { photographyPage } from "../Database";
import AutoSlide from "./AutoSlide";
import SectionPage from "./SectionPage";

function Photography() {
  const data = [bannerImg, bannerImg1, bannerImg, bannerImg];

  const targetRef = useRef(null);

  // Function to handle the button click event
  const scrollToElement = () => {
    // Scroll to the target element using scrollIntoView
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="w-full overflow-hidden">
      {/* Banner */}
      <div className="flex justify-center items-center mx-auto max-w-7xl mt-16 px-5">
        <div className="flex items-center lg:gap-10 gap-7 h-full flex-col md:flex-row">
          {/* Banner Description */}

          <div className="w-full overflow-hidden" data-aos="zoom-out">
            <h1 className="text-4xl lg:text-5xl text-primary leading-tight font-bold font-serif text-center sm:text-5xl md:text-left">
              {photographyPage.bannerInfo.title}
            </h1>

            <p className="mt-7 leading-7">{photographyPage.bannerInfo.des}</p>

            <div className="mt-6 text-center md:text-left">
              <button
                onClick={scrollToElement}
                className="py-2 transition hover:bg-black hover:text-white px-5 border rounded border-black font-bold font-serif"
              >
                Learn More
              </button>
            </div>
          </div>

          {/* Banner Image */}

          <div className="w-full overflow-hidden">
            <img src={bannerImg} alt="" data-aos="zoom-out" />
          </div>
        </div>
      </div>

      {/* Section  */}
      <SectionPage
        aboutName={photographyPage.pagesectiondetails.tile}
        heading={photographyPage.pagesectiondetails.mainHeading}
        pageAbout={photographyPage.pagesectiondetails.pageThree}
      />

      {/* Pictures Slides */}
      <div className="mt-28 mx-auto max-w-5xl px-5">
        <AutoSlide slides={data} />
      </div>

      {/* Why Us Section */}

      <div className="mt-20 container mx-auto max-w-4xl px-5">
        <h1 className="font-bold font-serif text-3xl md:text-4xl lg:text-7xl">
          But, Why Us?
        </h1>

        <p className="mt-4 leading-9 md:text-xl">
          We invite you to take a visual journey through moments frozen in time.
          Our passion for photography is not just about capturing images; it's
          about telling stories, evoking emotions, and celebrating the beauty of
          life. We're thrilled to share our work with you.
        </p>
      </div>

      {/* Ready to contact */}
      <Ready
        targetRef={targetRef}
        title="Ready For us to capture your moment"
      />
    </div>
  );
}

export default Photography;
