import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import Home from "./Home";
import Shop from "./components/Shop";
import { useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import AboutUs from "./components/AboutUs";
import ProductPage from "./components/Shop/ProductPage";
import Cartpage from "./components/Shop/Cartpage";
import PrintingPage from "./components/PrintingPage";
import Photography from "./components/Photography";
import EventPage from "./components/EventPage";
import WhatWeDo from "./components/WhatWeDo";
import ScrollToTop from "./components/ScrollToTop";
import OurWork from "./components/OurWork";

function App() {
  const location = useLocation();

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, [location]);

  return (
    <ScrollToTop>
      {location.pathname.includes("shop") ? null : (
        <div className="pb-6 bg-white sticky top-0 z-50">
          <Header />
          {/* Lines */}
          <div className="bg-black w-full py-0.5"></div>
        </div>
      )}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/product/t-shirt" element={<ProductPage />} />
        <Route path="/shop/cart" element={<Cartpage />} />
        <Route path="/printing" element={<PrintingPage />} />
        <Route path="/photography" element={<Photography />} />
        <Route path="/event-planning" element={<EventPage />} />
        <Route path="/what-we-do" element={<WhatWeDo />} />
        <Route path="/our-work" element={<OurWork />} />
        <Route path="/*" element={<h1>No Page </h1>} />
      </Routes>
      {location.pathname.includes("shop") ? null : <Footer />}
    </ScrollToTop>
  );
}

export default App;
