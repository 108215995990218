import React, { useState } from "react";
import shirt from "../../images/shirt.jpg";
import Product from "./Product";
import cap1 from "../../images/Image 35.jpg";
import cap2 from "../../images/Image 36.jpg";
import hat from "../../images/Image 37.jpg";
import shirt1 from "../../images/Image 38.jpg";

function ProductPage() {
  const [count, setcount] = useState(1);

  const increaseCount = () => {
    setcount(count + 1);
  };

  const decreaseCount = () => {
    count === 1 ? setcount(1) : setcount(count - 1);
  };

  return (
    <div className="container mx-auto max-w-5xl mt-16 px-9">
      <div className="flex flex-col md:flex-row md:justify-between md:gap-20 lg:gap-24  gap-10">
        {/* Product Image Des */}

        <div className="w-full">
          <div className="w-full">
            <img src={shirt} alt="" className="w-full" data-aos="zoom-in" />
          </div>

          <div className="mt-8 grid grid-cols-4 gap-3">
            <img
              src={shirt}
              alt=""
              className="w-full max-w-[120px] p-2 cursor-pointer border"
            />
            <img
              src={shirt}
              alt=""
              className="w-full max-w-[120px] p-2 cursor-pointer"
            />
            <img
              src={shirt}
              alt=""
              className="w-full max-w-[120px] p-2 cursor-pointer"
            />
            <img
              src={shirt}
              alt=""
              className="w-full max-w-[120px] p-2 cursor-pointer"
            />
          </div>
        </div>

        {/* Product Description */}
        <div className="w-full " data-aos="zoom-in">
          {/* name */}
          <h1 className="font-serif font-bold text-3xl md:text-4xl lg:text-5xl">
            Black T-shirt
          </h1>
          {/* Price */}
          <h1 className="font-serif font-bold mt-5 text-3xl text-slate-800">
            148.88 $
          </h1>
          {/* Description */}
          <p className="font-bold mt-5 text-gray-700 leading-7">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium
            praesentium totam fugit quas incidunt sapiente ut mollitia et rerum,
            magni similique qui voluptates commodi ipsam aut doloremque deleniti
            obcaecati corporis?
          </p>
          {/* Quantity */}
          <div className="flex items-center gap-7 mt-8">
            <h1 className="font-bold font-serif text-lg">QTY</h1>
            <div className="flex gap-3 font-bold items-center">
              <button
                className="md:text-2xl font-serif"
                onClick={decreaseCount}
              >
                -
              </button>
              <p className="font-serif md:text-2xl">{count}</p>
              <button
                className="md:text-2xl font-serif"
                onClick={increaseCount}
              >
                +
              </button>
            </div>
          </div>

          {/* Buttons Action */}
          <div className="mt-9">
            <button className="bg-black text-white px-9 py-2.5 rounded font-serif font-bold">
              Add to cart
            </button>
          </div>
        </div>
      </div>

      <div className="mt-20">
        <h1 className="text-center font-serif font-bold text-2xl md:text-4xl">
          Related Products
        </h1>

        <div className="grid grid-cols-2 justify-center md:grid-cols-4 gap-5 mt-7">
          <Product image={shirt1} name="Product name" />
          <Product image={hat} name="Product name" />
          <Product image={cap1} name="Product name" />
          <Product image={cap2} name="Product name" />
        </div>
      </div>
    </div>
  );
}

export default ProductPage;
