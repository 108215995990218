export const printingPage = {
  bannerInfo: {
    img: "url",
    title: "Your Printing Solutions Partner",
    des: "Printing isn't just a service; it's an art form. Discover how our skilled team combines creativity and precision to bring your ideas to life in vivid color and sharp detail.",
  },

  pagesectiondetails: {
    tile: "ABOUT OUR PRINTING SERVICES",
    mainHeading: "Elevate Your Brand with Professional Printing",
    pageThree: [
      {
        title: "Quality Assurance",
        des: "We take pride in delivering prints that meet the highest standards of quality. Our rigorous quality control ensures that every print is flawless.",
      },

      {
        title: "Fast Turnaround",
        des: "Need your prints in a hurry? We offer fast turnaround times without compromising on quality, so you can meet your deadlines with confidence.",
      },

      {
        title: "Competitive Pricing",
        des: "We offer competitive pricing without sacrificing quality. Get premium prints at a price that fits your budget",
      },
    ],
  },
};

export const photographyPage = {
  bannerInfo: {
    img: "url",
    title: "Discover the World Through My Lens",
    des: "Our photography is a reflection of the world's beauty and the emotions it evokes. Join us as we explore the power of visual storytelling together.",
  },

  pagesectiondetails: {
    tile: "ABOUT OUR PHOTOGRAPHY SERVICES",
    mainHeading: "Capturing Moments in Time",
    pageThree: [
      {
        title: "Professionalism",
        des: "With years of experience, we bring a high level of professionalism to every project. We're committed to delivering exceptional results on time and within budget.",
      },

      {
        title: "Passion for Photography",
        des: "Photography is more than just a job; it's our lifelong passion. We pour our hearts and souls into every shot, ensuring that each image is a work of art.",
      },

      {
        title: "Personalized Service",
        des: " Whether you're looking for prints, digital images, or customized photography sessions, we offer personalized service to meet your specific needs.",
      },
    ],
  },
};

export const eventPlanning = {
  bannerInfo: {
    img: "url",
    title: "Let's Plan Something Spectacular Together",
    des: "From intimate gatherings to grand celebrations, we've got your event needs covered. Join us in the journey of planning, designing, and executing events that exceed your expectations.",
  },

  pagesectiondetails: {
    tile: "ABOUT OUR EVENT PLANNING",
    mainHeading: "Turning Your Ideas into Extraordinary Events",
    pageThree: [
      {
        title: "Attention to Detail",
        des: "It's the little things that make a big difference. We're known for our meticulous attention to detail, ensuring that every aspect of your event is carefully curated.",
      },

      {
        title: "Stress-Free Planning",
        des: "Planning an event can be overwhelming. We'll take care of the logistics, timelines, and coordination, so you can relax and enjoy your event.",
      },

      {
        title: "Personalized Service",
        des: "We believe that every event should be a reflection of your unique style and preferences. Our planners work closely with you to understand your vision and execute it flawlessly.",
      },
    ],
  },
};
