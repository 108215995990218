import React from "react";
import c1 from "../images/ccc.png";

function Clients() {
  return (
    <div className="container mx-auto max-w-7xl my-28 px-9" data-aos="zoom-out">
      <h1 className="font-serif font-bold text-3xl sm:text-4xl md:text-5xl text-center">
        Who we’ve worked with
      </h1>

      <div className="w-full mt-14 flex justify-center">
        <img src={c1} alt="" className="w-full max-w-xl" />
      </div>
    </div>
  );
}

export default Clients;
