import React, { useState } from "react";
import { Humburger } from "../Icons";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import photologo from "../assets/logos/photography.png";
import eventLogo from "../assets/logos/event.png";
import printingLogo from "../assets/logos/printxxx.png";
import main from "../assets/logos/pr.png";

function Header() {
  const [menuOpen, setmenuOpen] = useState(false);

  const [ServiceOpen, setServiceOpen] = useState(false);

  const documentUrl = document.location.pathname.replace("/", "");

  return (
    <div className="container mx-auto max-w-7xl px-4">
      {/* Menu Bar */}
      {menuOpen && (
        <div className="sidebar-overlay fixed top-0 left-0 w-full h-full bg-black z-50 py-12 px-8 md:px-20 overflow-y-auto">
          <Menu openMenu={setmenuOpen} />
        </div>
      )}

      {/* Header */}

      <div className="flex items-center justify-between py-5">
        {/* Logo */}
        <div className="flex items-center gap-20">
          <div className="">
            <Link to="/">
              <div>
                <img
                  src={
                    documentUrl === "photography"
                      ? photologo
                      : documentUrl === "event-planning"
                      ? eventLogo
                      : documentUrl === "printing"
                      ? printingLogo
                      : main
                  }
                  className="w-16 md:w-20"
                  alt=""
                />
              </div>
            </Link>
          </div>

          {/* Nav */}
          <ul className="items-center gap-10 hidden md:flex">
            <li>
              <Link
                onMouseEnter={() => {
                  setServiceOpen(false);
                }}
                to="/what-we-do"
                className="text-lg font-semibold hover:text-green-700 transition delay-150 font-serif"
              >
                What We Do
              </Link>
            </li>

            <li>
              <div className="relative w-fit">
                <button
                  onMouseEnter={() => {
                    setServiceOpen(true);
                  }}
                  className="text-lg font-semibold hover:text-green-700 transition delay-150 font-serif"
                >
                  Services
                </button>

                {ServiceOpen && (
                  <div
                    onMouseLeave={() => {
                      setServiceOpen(false);
                    }}
                    className="bg-white absolute px-12 py-10 drop-shadow-lg z-50 top-16 w-fit"
                  >
                    <ul className="flex flex-col gap-5 w-100">
                      <li>
                        <Link
                          to="/photography"
                          className="font-serif font-bold hover:text-green-600 transition"
                          onClick={() => {
                            setServiceOpen(false);
                          }}
                        >
                          Photography & Videography
                        </Link>
                        <p className="mt-2">
                          Explore our team's ways of improving, rebuilding &
                          designing your website.
                        </p>
                      </li>

                      <li>
                        <Link
                          to="/printing"
                          className="font-serif font-bold hover:text-green-600 transition"
                          onClick={() => {
                            setServiceOpen(false);
                          }}
                        >
                          Printing Services & Branding
                        </Link>
                        <p className="mt-2">
                          Explore our team's ways of improving, rebuilding &
                          designing your website.
                        </p>
                      </li>

                      <li>
                        <Link
                          to="/event-planning"
                          className="font-serif font-bold hover:text-green-600 transition"
                          onClick={() => {
                            setServiceOpen(false);
                          }}
                        >
                          Event Planning
                        </Link>
                        <p className="mt-2">
                          Explore our team's ways of improving, rebuilding &
                          designing your website.
                        </p>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </li>

            <li>
              <Link
                onMouseEnter={() => {
                  setServiceOpen(false);
                }}
                to="/contact"
                className="text-lg font-semibold hover:text-green-700 transition delay-150 font-serif"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>

        {/* Humburger */}
        <div className="flex items-center gap-5 lg:gap-10">
          <div
            className="cursor-pointer"
            onClick={() => {
              setmenuOpen(!menuOpen);
            }}
          >
            <Humburger />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
