const { createContext, useState, useEffect } = require("react");

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setuser] = useState({
    id: "meme",
    fullname: "",
    email: "",
    token: "",
  });

  useEffect(() => {}, []);

  return (
    <AuthContext.Provider value={(user, setuser)}>
      {children}
    </AuthContext.Provider>
  );
};
