import React, { useEffect, useState } from "react";

function AutoSlide({ slides, interval = 5000 }) {
  const [currentSlide, setcurrentSlide] = useState(0);

  const nextSlide = () => {
    return currentSlide === slides.length - 1
      ? setcurrentSlide(0)
      : setcurrentSlide(currentSlide + 1);
  };

  // eslint-disable-next-line

  useEffect(() => {
    const intervalS = setInterval(() => {
      nextSlide();
    }, interval);

    return () => {
      clearInterval(intervalS);
    };
    // eslint-disable-next-line
  }, [currentSlide, interval]);

  return (
    <div className="container mx-auto w-full">
      <div className="w-full overflow-hidden">
        {slides.map((slide, index) => {
          if (index === currentSlide) {
            return (
              <div
                key={index}
                data-aos="zoom-out"
                className="w-full max-h-[600px]"
              >
                <img
                  src={slide}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}

export default AutoSlide;
