import React from "react";
import Banner from "./components/Banner";
import Portfolio from "./components/Portfolio";
import About from "./components/About";
import { Link } from "react-router-dom";
import Clients from "./components/Clients";

function Home() {
  return (
    <>
      {/* Banner */}

      <Banner />

      {/* Abbout Us */}
      <About />

      {/* Clients Section */}

      <Clients />

      {/* Portfolio */}
      <Portfolio />

      {/* Mid Action */}
      <div className="my-28 container mx-auto px-4 max-w-5xl">
        <p className="text-center text-xl sm:text-3xl md:4xl">
          Too early to take the first step?
        </p>

        <div className="flex justify-center mt-3">
          <Link
            to="/about-us"
            className="font-serif font-bold text-center inline-flex text-3xl md:text-5xl"
          >
            Learn more about us
          </Link>
        </div>
      </div>
    </>
  );
}

export default Home;
